<template>
  <v-app>
    <v-container class="d-flex justify-center mb-6">
      <div class="pt-12">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card class="no-border">
            <v-card-title class="pb-0">
              <div class="text-center pb-16">
                <img :src="require('@/assets/logo-cor-primaria.png')" width="148" class="hidden-xs-only pt-9" />
              </div>
            </v-card-title>
          </v-card>
          <v-card v-if="waitValidatetoken == false" style="width: 340px" class="no-border">
            <v-card-title class="pb-0 pt-0 title-login-flow"> Redefinir sua senha </v-card-title>
            <v-card-title class="body-login-flow">
              Crie uma senha complexa para aumentar a segurança do acesso a sua conta.
            </v-card-title>
            <v-card-text class="px-4 pb-4 pt-4">
              <v-row style="padding-bottom: 16px">
                <v-col cols="12" sm="12" class="mb-1 mt-1 py-0">
                  <v-text-field
                    dense
                    v-model="newPasswordForm.newPassword"
                    ref="passwordFieldOne"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showPassword ? 'text' : 'password'"
                    :rules="[
                      rules.passwordRules,
                      rules.password8Rules,
                      rules.passwordNumberRules,
                      rules.passwordUpperCaseRules,
                      rules.passwordLowercaseCaseRules,
                      rules.passwordSpecialCharacterRules,
                      rules.passwordAlreadyUsed,
                    ]"
                    hide-details="true"
                    class="input-group--focused"
                    label="Nova senha *"
                    outlined
                    min="8"
                    persistent-placeholder
                    @click:append="showPassword = !showPassword"
                    @input="updatePassword"
                    @blur="matchPassword()"
                    @keyup="matchPassword()"
                  />
                </v-col>
              </v-row>
              <v-row v-show="showErrorPassword == true" style="position: relative; padding: 0px 0px 20px 0px">
                <v-col cols="12" class="my-0 py-0">
                  <div v-for="item in validations" :key="item.id" :id="item.id" :style="item.style">
                    <v-icon :id="item.id + '-button'" small :style="item.button">mdi-close-circle-outline</v-icon>
                    <span>{{ item.text }}</span>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" class="my-0 py-0">
                  <v-text-field
                    dense
                    v-model="newPasswordForm.newPasswordConfirmation"
                    :append-icon="showConfirm ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showConfirm ? 'text' : 'password'"
                    :rules="[rules.matchingPassword]"
                    class="input-group--focused"
                    label="Confirmar senha *"
                    outlined
                    min="8"
                    persistent-placeholder
                    @click:append="showConfirm = !showConfirm"
                    :error-messages="passwordConfirmMessage"
                    @blur="matchPassword()"
                    @keyup="matchPassword()"
                  />
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions class="button-action-login-flow">
              <v-spacer />
              <v-btn :disabled="!valid || !newPasswordForm.newPassword" color="primary" @click="confirmNewPassword()">
                Criar senha
              </v-btn>
            </v-card-actions>
          </v-card>
          <v-card v-if="waitValidatetoken == true" style="width: 340px" class="no-border">
            <v-card-title class="pb-0 pt-0 title-login-flow"> Validando token... </v-card-title>
          </v-card>
        </v-form>
      </div>
    </v-container>
  </v-app>
</template>

<script>
import notificationServices from '@/services/notificationServices.js';
import { mapGetters, mapActions } from 'vuex';
import { eventBus } from '../../../../main.js';
import Vue from 'vue';

export default {
  name: 'ModalForgotPasswordConfirm',

  props: {
    token: {
      required: true,
    },
  },

  data() {
    return {
      showConfirm: false,
      hasError: false,
      errorMessage: '',
      newPasswordForm: {
        newPassword: '',
        newPasswordConfirmation: '',
        token: '',
      },
      notValid: false,
      showErrorPassword: false,
      rules: {
        newPassword: (v) => !!v || 'Nova senha é obrigatória',
        passwordRules: (v) => !!v || 'Preencha uma senha válida',
        password8Rules: (v) => this.isGreaterThan8AndLessThan20CharPassword(v) || 'De 8 a 20 caracteres',
        passwordNumberRules: (v) => this.hasANumberInPassword(v) || 'Um número de 0 a 9',
        passwordUpperCaseRules: (v) => this.hasAUpperWordInPassword(v) || 'Letra maiúscula',
        passwordLowercaseCaseRules: (v) => this.hasALowerWordInPassword(v) || 'Letra minúscula',
        passwordSpecialCharacterRules: (v) => this.hasSpecialCharInPassword(v) || 'Um caracter especial "!@#$%"',
        passwordAlreadyUsed: () => true || this.passwordAlreadyUsedMessage,
        matchingPassword: () => true || this.passwordConfirmMessage,
      },
      passwordAlreadyUsedMessage: '',
      passwordConfirmMessage: '',
      valid: false,
      showPassword: false,
      waitValidatetoken: false,
      validations: [
        {
          id: 'sizeChar',
          text: 'De 8 a 20 caracteres',
          style:
            'margin-bottom: 8px; color: #962D33; font-size: 12px; font-style: normal; font-weight: 400; line-height: 133%; letter-spacing: 0.01em;',
          button: 'font-size: 14px; margin-right: 9.33px; color: #962D33;',
        },
        {
          id: 'sizeNumber',
          text: 'Um número de 0 a 9',
          style:
            'margin-bottom: 8px; color: #962D33; font-size: 12px; font-style: normal; font-weight: 400; line-height: 133%; letter-spacing: 0.01em;',
          button: 'font-size: 14px; margin-right: 9.33px; color: #962D33;',
        },
        {
          id: 'upper',
          text: 'Letra maiúscula',
          style:
            'margin-bottom: 8px; color: #962D33; font-size: 12px; font-style: normal; font-weight: 400; line-height: 133%; letter-spacing: 0.01em;',
          button: 'font-size: 14px; margin-right: 9.33px; color: #962D33;',
        },
        {
          id: 'lower',
          text: 'Letra minúscula',
          style:
            'margin-bottom: 8px; color: #962D33; font-size: 12px; font-style: normal; font-weight: 400; line-height: 133%; letter-spacing: 0.01em;',
          button: 'font-size: 14px; margin-right: 9.33px; color: #962D33;',
        },
        {
          id: 'specialChar',
          text: 'Um caracter especial "!@#$%"',
          style:
            'margin-bottom: 8px; color: #962D33; font-size: 12px; font-style: normal; font-weight: 400; line-height: 133%; letter-spacing: 0.01em;',
          button: 'font-size: 14px; margin-right: 9.33px; color: #962D33;',
        },
        {
          id: 'passwordAlready',
          text: 'Senha diferente das três últimas',
          style:
            'margin-bottom: 0px; color: #962D33; font-size: 12px; font-style: normal; font-weight: 400; line-height: 133%; letter-spacing: 0.01em;',
          button: 'font-size: 14px; margin-right: 9.33px; color: #962D33;',
        },
      ],
    };
  },

  
  created() {
    this.init();
  },

  methods: {
    ...mapActions('notifications', ['wasLast3Passwords1', 'changeShowToast']),

    async init() {
      this.waitValidatetoken = true;
      try {
        var response = await notificationServices.validateTokenCrypt(this.token)

        if (response.status === 200 && response.data === false) {
          localStorage.setItem('linkexpiredconfirmemail', true);
          this.$router.push(`/forgot/email`);
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.waitValidatetoken = false;
      }
    },

    async confirmNewPassword() {
      if (!this.valid) return;

      if (this.newPasswordForm.newPassword === this.newPasswordForm.newPasswordConfirmation) {
        const user = { ...this.newPasswordForm };
        user.newPassword = btoa(user.newPassword);
        user.newPasswordConfirmation = btoa(user.newPasswordConfirmation);
        user.email = this.email;
        //user.accessCode = Vue.prototype.$userAccessCode;
        user.accessCode = this.token;
        try {
          var response = await notificationServices.confirmNewPassword(user);
          if (response.status === 200 && response.data.id === null) {
            this.hasError = true;
            this.errorMessage = response.data.emailAddress;
          } else if (response.status === 200) {            
            this.hasError = false;
            this.errorMessage = '';
            localStorage.removeItem('access_Token');
            localStorage.removeItem('refresh_Token');
            Vue.prototype.$userAccessCode = null;
            if (response.data.is_enabled) {
              this.showToast('Senha redefinida com sucesso. Realize o login abaixo', 'success', 14);
              this.$router.push('/login');
            } else {
              this.$router.push(`/registration/first/access/${response.data.id}`);
            }
          }
        } catch (error) {
          this.hasError = true;
          this.errorMessage = error.response.data.status;
          this.showToast(this.errorMessage, 'error');
        }
      } else {
        this.hasError = true;
        this.errorMessage = 'As senhas devem ser iguais';
      }
    },

    matchPassword() {
      if (this.newPasswordForm.newPassword === this.newPasswordForm.newPasswordConfirmation) {
        this.passwordConfirmMessage = '';
        this.rules.matchingPassword = true;
      } else {
        this.passwordConfirmMessage = 'Senhas não coincidem';
        this.rules.matchingPassword = false;
      }
    },

    async wasLast3Passwords(value) {
      if (value) {
        let data = {
          identificator: this.token,
          password: value,
        };

        await this.wasLast3Passwords1(data).then(() => {
          if (this.validPassword == true) {
            this.passwordAlreadyUsedMessage = '';
            this.rules.passwordAlreadyUsed = false;
          } else {
            this.passwordAlreadyUsedMessage = 'Senha diferente das três últimas';
            this.rules.passwordAlreadyUsed = true;
          }
        });
      }
    },

    async updatePassword(value) {
      await this.wasLast3Passwords(value);

      if (value) {
        this.updateStyleErrorPassword('sizeChar', this.isGreaterThan8AndLessThan20CharPassword(value));
        this.updateStyleErrorPassword('sizeNumber', this.hasANumberInPassword(value));
        this.updateStyleErrorPassword('upper', this.hasAUpperWordInPassword(value));
        this.updateStyleErrorPassword('lower', this.hasALowerWordInPassword(value));
        this.updateStyleErrorPassword('specialChar', this.hasSpecialCharInPassword(value));
        this.updateStyleErrorPassword('passwordAlready', !this.validPassword);
      }

      const isValid = this.$refs.passwordFieldOne.validate();

      this.showErrorPassword = !isValid;
    },

    updateStyleErrorPassword(idElement, valid) {
      var element = document.getElementById(idElement);
      element.style.color = valid ? '#288569' : '#962D33';
      var button = document.getElementById(idElement + '-button');
      button.classList.remove(valid ? 'mdi-close-circle-outline' : 'mdi-check-circle-outline');
      button.classList.remove(valid ? 'red-button' : 'green-button');
      button.classList.add(valid ? 'mdi-check-circle-outline' : 'mdi-close-circle-outline');
      button.classList.add(valid ? 'green-button' : 'red-button');
    },

    isGreaterThan8AndLessThan20CharPassword(val) {
      return /^(?=.*[a-zA-Z\d]).{8,20}$/.test(val) === true;
    },

    hasANumberInPassword(val) {
      return /^(?=.*[0-9])/.test(val);
    },

    hasAUpperWordInPassword(val) {
      return /^(?=.*[A-Z])/.test(val) === true;
    },

    hasALowerWordInPassword(val) {
      return /^(?=.*[a-z])/.test(val) === true;
    },

    hasSpecialCharInPassword(val) {
      return /^(?=.*[@!#$%^&+=*()^{}~])/.test(val) === true;
    },

    showToast(message, type, topToast) {
      let toast = {
        show : true,
        type : type,
        message : message,
        topToast: topToast
      }
      eventBus.$emit('openToast', toast);
    },
  },

  computed: {
    ...mapGetters({
      validPassword: 'notifications/getValidPassword',
    }),
  },
};
</script>
<style scoped>
.green-button {
  color: #288569 !important;
}

.red-button {
  color: #962d33 !important;
}

.no-border {
  box-shadow: none !important;
  border: 0px solid #e7e7fa !important;
}
</style>
