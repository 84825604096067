var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    [
      _c("v-container", { staticClass: "d-flex justify-center mb-6" }, [
        _c(
          "div",
          { staticClass: "pt-12" },
          [
            _c(
              "v-form",
              {
                ref: "form",
                attrs: { "lazy-validation": "" },
                model: {
                  value: _vm.valid,
                  callback: function ($$v) {
                    _vm.valid = $$v
                  },
                  expression: "valid",
                },
              },
              [
                _c(
                  "v-card",
                  { staticClass: "no-border" },
                  [
                    _c("v-card-title", { staticClass: "pb-0" }, [
                      _c("div", { staticClass: "text-center pb-16" }, [
                        _c("img", {
                          staticClass: "hidden-xs-only pt-9",
                          attrs: {
                            src: require("@/assets/logo-cor-primaria.png"),
                            width: "148",
                          },
                        }),
                      ]),
                    ]),
                  ],
                  1
                ),
                _vm.waitValidatetoken == false
                  ? _c(
                      "v-card",
                      {
                        staticClass: "no-border",
                        staticStyle: { width: "340px" },
                      },
                      [
                        _c(
                          "v-card-title",
                          { staticClass: "pb-0 pt-0 title-login-flow" },
                          [_vm._v(" Redefinir sua senha ")]
                        ),
                        _c("v-card-title", { staticClass: "body-login-flow" }, [
                          _vm._v(
                            " Crie uma senha complexa para aumentar a segurança do acesso a sua conta. "
                          ),
                        ]),
                        _c(
                          "v-card-text",
                          { staticClass: "px-4 pb-4 pt-4" },
                          [
                            _c(
                              "v-row",
                              { staticStyle: { "padding-bottom": "16px" } },
                              [
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "mb-1 mt-1 py-0",
                                    attrs: { cols: "12", sm: "12" },
                                  },
                                  [
                                    _c("v-text-field", {
                                      ref: "passwordFieldOne",
                                      staticClass: "input-group--focused",
                                      attrs: {
                                        dense: "",
                                        "append-icon": _vm.showPassword
                                          ? "mdi-eye"
                                          : "mdi-eye-off",
                                        type: _vm.showPassword
                                          ? "text"
                                          : "password",
                                        rules: [
                                          _vm.rules.passwordRules,
                                          _vm.rules.password8Rules,
                                          _vm.rules.passwordNumberRules,
                                          _vm.rules.passwordUpperCaseRules,
                                          _vm.rules.passwordLowercaseCaseRules,
                                          _vm.rules
                                            .passwordSpecialCharacterRules,
                                          _vm.rules.passwordAlreadyUsed,
                                        ],
                                        "hide-details": "true",
                                        label: "Nova senha *",
                                        outlined: "",
                                        min: "8",
                                        "persistent-placeholder": "",
                                      },
                                      on: {
                                        "click:append": function ($event) {
                                          _vm.showPassword = !_vm.showPassword
                                        },
                                        input: _vm.updatePassword,
                                        blur: function ($event) {
                                          return _vm.matchPassword()
                                        },
                                        keyup: function ($event) {
                                          return _vm.matchPassword()
                                        },
                                      },
                                      model: {
                                        value: _vm.newPasswordForm.newPassword,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.newPasswordForm,
                                            "newPassword",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "newPasswordForm.newPassword",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-row",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.showErrorPassword == true,
                                    expression: "showErrorPassword == true",
                                  },
                                ],
                                staticStyle: {
                                  position: "relative",
                                  padding: "0px 0px 20px 0px",
                                },
                              },
                              [
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "my-0 py-0",
                                    attrs: { cols: "12" },
                                  },
                                  _vm._l(_vm.validations, function (item) {
                                    return _c(
                                      "div",
                                      {
                                        key: item.id,
                                        style: item.style,
                                        attrs: { id: item.id },
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            style: item.button,
                                            attrs: {
                                              id: item.id + "-button",
                                              small: "",
                                            },
                                          },
                                          [_vm._v("mdi-close-circle-outline")]
                                        ),
                                        _c("span", [_vm._v(_vm._s(item.text))]),
                                      ],
                                      1
                                    )
                                  }),
                                  0
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "my-0 py-0",
                                    attrs: { cols: "12", sm: "12" },
                                  },
                                  [
                                    _c("v-text-field", {
                                      staticClass: "input-group--focused",
                                      attrs: {
                                        dense: "",
                                        "append-icon": _vm.showConfirm
                                          ? "mdi-eye"
                                          : "mdi-eye-off",
                                        type: _vm.showConfirm
                                          ? "text"
                                          : "password",
                                        rules: [_vm.rules.matchingPassword],
                                        label: "Confirmar senha *",
                                        outlined: "",
                                        min: "8",
                                        "persistent-placeholder": "",
                                        "error-messages":
                                          _vm.passwordConfirmMessage,
                                      },
                                      on: {
                                        "click:append": function ($event) {
                                          _vm.showConfirm = !_vm.showConfirm
                                        },
                                        blur: function ($event) {
                                          return _vm.matchPassword()
                                        },
                                        keyup: function ($event) {
                                          return _vm.matchPassword()
                                        },
                                      },
                                      model: {
                                        value:
                                          _vm.newPasswordForm
                                            .newPasswordConfirmation,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.newPasswordForm,
                                            "newPasswordConfirmation",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "newPasswordForm.newPasswordConfirmation",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-card-actions",
                          { staticClass: "button-action-login-flow" },
                          [
                            _c("v-spacer"),
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  disabled:
                                    !_vm.valid ||
                                    !_vm.newPasswordForm.newPassword,
                                  color: "primary",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.confirmNewPassword()
                                  },
                                },
                              },
                              [_vm._v(" Criar senha ")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.waitValidatetoken == true
                  ? _c(
                      "v-card",
                      {
                        staticClass: "no-border",
                        staticStyle: { width: "340px" },
                      },
                      [
                        _c(
                          "v-card-title",
                          { staticClass: "pb-0 pt-0 title-login-flow" },
                          [_vm._v(" Validando token... ")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }